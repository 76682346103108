<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import {
	socialLinks
} from '../../data/socialLinks';

export default {
	components: {
		FooterCopyright
	},
	props: {
		info: {
			type: Object
		}
	},
	data() {
		return {
			socials: socialLinks,
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>
	<div class="w-full bg-[#333333]">
		<div class="container mx-auto">
			<div class="pt-20 sm:pt-30 pb-8 flex flex-col items-center sm:flex-row pl-6 sm:pl-0">
				<!-- Footer social links -->
				<div class="w-full md:w-2/6 flex flex-col justify-center sm:items-start mb-6 sm:mb-0">
					<div>
						<router-link to="/"><img v-if="theme === 'light'" src="@/assets/images/logo.webp"
								alt="India IYDF-British IYDF" class="w-40" />
							<img v-else src="@/assets/images/logo.webp" class="w-40" alt="India IYDF-British IYDF" />
						</router-link>
					</div>
					<!-- <div class="mt-10 text-left :sm-30 text-[#999] mb-2">Follow Us：</div>
					<ul class="flex">
						<a v-for="social in socials" :key="social.id" :href="social.url" target="__blank"
							class="text-white cursor-pointer mr-4">
							<i :data-feather="social.icon" class="w-6 sm:w-6 h-6 sm:h-6"></i>
						</a>
					</ul> -->
				</div>
				<div class="w-full md:w-4/6 float-right text-white">
					<h2 class="text-left font-bold md:text-xl text-sm">For inquiries, please refer to our Contact Info
						below</h2>

					<div class="block text-left text-[#999] my-8">
						<p class="text-sm mb-1">{{ info.adress }}</p>
						<!-- <p class="text-sm">Miami, FL 33133，美国。</p> -->
					</div>

					<div class="block">
						<div class="flex items-center mb-2">
							<i data-feather="phone" class="w-4 sm:w-4 h-4 sm:h-4"></i>
							<p class="ml-2 text-sm text-nowrap">Tel：{{ info.phone }}</p>
						</div>
						<div class="flex items-center">
							<i data-feather="mail" class="w-4 sm:w-4 h-4 sm:h-4"></i>
							<p class="ml-2 text-sm text-nowrap">Email: {{ info.email }}</p>
						</div>
					</div>
				</div>
				<!-- Footer copyright -->
			</div>
		</div>
		<FooterCopyright />
	</div>
</template>

<style scoped></style>