<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
		inputIdentifier: {
			type: String,
			default: '',
		},
		modelValue: {
			type: [String, Number],
			default: '',
		},
		inputType: {
			type: String,
			default: 'text',
		},
	},
};
</script>

<template>
	<div
		class="home-input flex items-center w-full px-5 py-2 border border-gray-300 dark:border-primary-dark bg-white border-opacity-50">
		<label v-if="label" class="text-sm text-primary-dark md-3 dark:text-primary-light text-nowrap" :for="label"><span
				class="text-red-500">*</span>{{
			label }}</label>
		<input class="w-full text-primary-dark text-sm" :id="inputIdentifier" :name="inputIdentifier"
			:aria-label="inputIdentifier" :value="modelValue" :type="inputType" v-bind="$attrs"
			@input="$emit('update:modelValue', $event.target.value)" required />
	</div>
</template>

<style lang="scss" scoped></style>
