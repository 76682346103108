<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'IYDF',
			author: 'Stoman',
		};
	},
};
</script>

<template>
	<div class="flex justify-center items-center text-center bg-[#111] h-24">
		<div class="font-general-regular text-sm text-white dark:text-ternary-light">
			Copyright (C) 2024 IYDF,CC BY 3.0
		</div>
	</div>
</template>

<style lang="scss" scoped></style>
