<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
		textareaIdentifier: {
			type: String,
			default: '',
		},
		modelValue: {
			type: [String, Number],
			default: '',
		},
	},
};
</script>

<template>
	<div
		class="home-textarea w-full px-5 py-2 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-white">
		<label v-if="label" class="block text-sm text-primary-dark dark:text-primary-light mb-4 text-nowrap"
			:for="textareaIdentifier"><span class="text-red-500">*</span>{{ label
			}}</label>
		<textarea class="w-full text-primary-dark text-sm" :id="textareaIdentifier" :name="textareaIdentifier"
			:aria-label="textareaIdentifier" v-bind="$attrs" :value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)" cols="14" rows="6"></textarea>
	</div>
</template>

<style lang="scss" scoped></style>
