<script>
import feather from 'feather-icons';

export default {
  name: 'HomeAbout',
  props: {
    info: {
      type: Object
    }
  },
  data: () => {
    return {
      theme: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('theme') || 'light';
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem('theme') || 'light';
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>

<template>
  <div class="sm:container sm:mx-auto px-2 sm:px-0">
    <section class="flex flex-col sm:justify-between items-center sm:flex-row mt-12 sm:mt-10">
      <!-- Banner right illustration -->
      <div class="w-full md:w-1/2 text-right float-right">
        <img :src="info.images[0].url" v-if="info.images" alt="WITH US-British International Youth Development Foundation-British IYDF-IYDF" />
      </div>
      <!-- Banner left contents -->
      <div class="w-full md:w-1/3 text-left mt-8 sm:mt-0">
        <div class="flex justify-center sm:justify-start">
          <h1
            class="font-general-semibold inline-flex font-bold pb-4 text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark border-solid border-b-gray-900 border-b-4 dark:text-primary-light uppercase">
            {{ info.title }}
            <!-- <h1
              class="font-general-semibold inline-flex font-bold pb-4 text-3xl md:text-3xl xl:text-4xl text-center sm:text-left text-ternary-dark dark:text-primary-light uppercase">
              我们一起</h1> -->
          </h1>
        </div>
        <p v-html="info.text"
          class="font-general-medium mt-6 text-sm sm:text-sm xl:text-sm text-left sm:text-left text-gray-400">
        </p>
        <div class="flex justify-center sm:block">
          <a href="/#/contact"
            class="flex justify-center items-center w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg dark:border-ternary-dark py-2.5 sm:py-3 shadow-lg rounded-full bg-black focus:ring-1 focus:ring-indigo-900 hover:bg-indigo-500 text-white hover:text-white duration-500"
            aria-label="Download Resume">
            <span class="text-sm sm:text-lg font-general-medium duration-100">{{ info.buttonName }}</span></a>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped></style>
