import service from "@/utils/request";
export const info = (data) => {
  return service({
    url: "/api/home/info",
    method: "post",
    data,
  });
};

export const setQuestion = (data) => {
  return service({
    url: "/api/home/question",
    method: "post",
    data,
  });
};
