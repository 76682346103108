<script>

export default {
  name: 'HomeAbout',
  props: {
    info: {
      type: Object
    }
  }
};
</script>

<template>
  <div class="w-full my-6" v-if="info.images">
    <div :style="{ backgroundImage: 'url(' + info.images[0].url + ')' }"
      class="h-80 w-full bg-scroll bg-cover bg-no-repeat bg-center">
      <div class="sm:container sm:max-auto flex items-center justify-center sm:justify-start h-full px-2">
        <p class="font-general-medium lg:w-1/3 mt-6 font-bold text-sm sm:text-xl  xl:text-xl text-center sm:text-left leading-10 text-white"
          v-html="info.text">
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
