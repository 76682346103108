<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	props: {
		info: {
			type: Object
		}
	},
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section :style="{ backgroundImage: info.images ? `url(${info.images[0].url})` : '' }"
		:class="`relative flex flex-col sm:justify-between justify-center items-center h-[18rem] md:h-[50rem] sm:flex-row bg-gray-950 bg-cover bg-no-repeat bg-center]`">
		<!-- Banner left contents -->
		<div class="w-full text-center relative z-10">
			<h1
				class="font-general-semibold font-bold text-base md:text-3xl xl:text-6xl text-center sm:text-center text-white dark:text-primary-light uppercase">
				{{ info.title }}
			</h1>
			<p
				class="font-general-medium mt-1 sm:mt-2 text-sm sm:text-xl xl:text-3xl text-center leading-none text-white pt-2">
				{{ info.title1 }}
			</p>
			<p class="font-general-medium mt-1 sm:mt-2 text-sm sm:text-base xl:text-lg text-center leading-none text-white">
				{{ info.title2 }}
			</p>
			<div class="flex justify-center">
				<a href="/#/contact"
					class="flex justify-center items-center rounded-full w-36 sm:w-48 mt-12 mb-6 sm:mb-0 text-lg py-2.5 sm:py-3 shadow-lg rounded-full bg-black focus:ring-1 focus:bg-black-900 hover:bg-black-500 text-gray-500 hover:text-white duration-500"
					aria-label="Download Resume">
					<span class="text-sm sm:text-lg font-general-medium text-white">Act Now</span></a>
			</div>
		</div>
		<div class="absolute bg-black/50 top-0 left-0 right-0 bottom-0 z-0"></div>
		<!-- Banner right illustration -->
		<!-- <div class="w-full md:w-2/3 text-right float-right">
			<img v-if="theme === 'light'" src="@/assets/images/developer.svg" alt="Developer" />
			<img v-else src="@/assets/images/developer-dark.svg" alt="Developer" />
		</div> -->
	</section>
</template>

<style scoped></style>
