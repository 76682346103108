import axios from 'axios' // 引入axios
const timestamp = parseInt((new Date().getTime() / 1000)).toString()
const timestamps = new Date().getTime().toString()
import CryptoJS from 'crypto-js'
const handeleStr=(str,str1) => {
  let length = str.length;
  let newArr = str.split('');
  newArr[length - 1] = str1;
  return newArr.join('');
}
const newTime = handeleStr(timestamps, timestamp.substr(timestamp.length - 1))
const service = axios.create({
  baseURL: './',
  timeout: 99999
})
// http request 拦截器
service.interceptors.request.use(
  config => {
    config.headers = {
      'Content-Type': 'application/json',
      'timestamp': newTime,
      'nonstr': CryptoJS.MD5(newTime + '_' + timestamp.substr(timestamp.length - 1)),
      ...config.headers
    }
    config.data = {
      operationID: new Date().getTime().toString(),
      ...config.data
    }
    config.params = {
      operationID: new Date().getTime().toString(),
      ...config.params
    }
    return config
  },
  error => {
    return error
  }
)

// http response 拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code === 0 || response.headers.success === 'true') {
      if (response.headers.msg) {
        response.data.msg = decodeURI(response.headers.msg)
      }
      return response.data
    }
  },
  error => {
    if (!error.response) {
      return console.error(error)
    }


    return error
  }
)
export default service
