<script>
import AppBanner from '@/components/shared/AppBanner';
import HomeAbout from '@/components/home/about';
import HomeContact from '@/components/home/contact';
import HomeFileMsg from '@/components/home/fileMessage';
import HomeCooperate from '@/components/home/cooperate';
import HomeForm from '@/components/home/form';
// import ProjectsGrid from '../components/projects/ProjectsGrid.vue';
// import Button from '../components/reusable/Button.vue';
import { info } from '@/api/index'
// import { useMeta } from 'vue-meta'
export default {
	name: 'Home',
	components: {
		AppBanner,
		// ProjectsGrid,
		HomeAbout,
		HomeFileMsg,
		HomeContact,
		HomeCooperate,
		HomeForm,
	},
	data: () => {
		return {
			topInfo: {},
			topInfo1: {},
			topInfo2: {},
			topInfo3: {},
			topInfo4: {},
			topInfo5: {},
		};
	},
	mounted() {
		// useMeta({
		// 	meta: [{
		// 			name: 'keywords',
		// 			content: 'India IYDF、British IYDF、British International Youth Development Foundation、International Youth Development Foundation、IYDF'
		// 		},
		// 		{
		// 			name: 'description',
		// 			content: 'IYDF is an international non-governmental humanitarian organization headquartered in the UK. Its mission is to narrow the education gap for poor children in developing and third world countries through cooperation with various institutions and individuals, and to strive to promote economic structural change and development. At the same time, its current open markets include the UK and India markets, and it is vigorously developing the International Youth Development Foundation to ultimately realize the vision of a "common future".'
		// 		},
		// 		{
		// 			name: 'author',
		// 			content: 'IYDF'
		// 		},
		// 		{
		// 			property: 'og:title',
		// 			content: 'IYDF Charity and public welfare websites-IYDFBritish International Youth Development Foundation-IYDFInternational Youth Development Foundation-India IYDF-British IYDF'
		// 		},
		// 		{
		// 			property: 'og:description',
		// 			content: 'IYDF is an international non-governmental humanitarian organization headquartered in the UK. Its mission is to narrow the education gap for poor children in developing and third world countries through cooperation with various institutions and individuals, and to strive to promote economic structural change and development. At the same time, its current open markets include the UK and India markets, and it is vigorously developing the International Youth Development Foundation to ultimately realize the vision of a "common future".'
		// 		},
		// 		{
		// 			property: 'og:site_name',
		// 			content: 'IYDF Charity and public welfare websites-IYDFBritish International Youth Development Foundation-IYDFInternational Youth Development Foundation-India IYDF-British IYDF'
		// 		},
		// 		{
		// 			name: 'twitter:title',
		// 			content: ' IYDF Charity and public welfare websites-IYDFBritish International Youth Development Foundation-IYDFInternational Youth Development Foundation-India IYDF-British IYDF'
		// 		},
		// 		{
		// 			name: 'twitter:description',
		// 			content: 'IYDF is an international non-governmental humanitarian organization headquartered in the UK. Its mission is to narrow the education gap for poor children in developing and third world countries through cooperation with various institutions and individuals, and to strive to promote economic structural change and development. At the same time, its current open markets include the UK and India markets, and it is vigorously developing the International Youth Development Foundation to ultimately realize the vision of a "common future".'
		// 		}
		// 	]
		// })
		this.getInfo()
	},
	methods: {
		getInfo() {
			info({ tableType: 1 })
				.then((res) => {
					if (res.code == 0) {
						this.topInfo = JSON.parse(res.data.list.find(item => item.subTableType == 'A').content)
						this.topInfo1 = JSON.parse(res.data.list.find(item => item.subTableType == 'B').content)
						this.topInfo2 = JSON.parse(res.data.list.find(item => item.subTableType == 'C').content)
						this.topInfo3 = JSON.parse(res.data.list.find(item => item.subTableType == 'D').content)
						this.topInfo4 = JSON.parse(res.data.list.find(item => item.subTableType == 'E').content)
						this.topInfo5 = JSON.parse(res.data.list.find(item => item.subTableType == 'G').content)
						console.log('数据G=====', this.topInfo)
						console.log('数据A=====', this.topInfo)
						console.log('数据B=====', this.topInfo1)
						console.log('数据C=====', this.topInfo2)
						console.log('数据D=====', this.topInfo3)
						console.log('数据E=====', this.topInfo4)
					}
				})
		}
	},
};
</script>

<template>
	<div class="mx-auto">
		<metainfo></metainfo>
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" :info="topInfo" />
		<!--关于我们-->
		<HomeAbout :info="topInfo1" />
		<!--文件信息-->
		<HomeFileMsg :info="topInfo2" />
		<!--立即加入-->
		<HomeContact :info="topInfo3" />
		<!--地区合作-->
		<HomeCooperate :info="topInfo4" />
		<!--form-->
		<HomeForm :info="topInfo5" />
		<!-- Projects -->
		<!-- <ProjectsGrid /> -->

		<!-- Load more projects button -->
		<!-- <div class="mt-10 sm:mt-20 flex justify-center">
			<router-link to="/projects"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects">
				<Button title="More Projects" />
			</router-link>
		</div> -->
	</div>
</template>

<style scoped></style>
