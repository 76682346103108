<script>
import feather from 'feather-icons';
import Button from '@/components//reusable/Button.vue';
import FormInput from '@/components//reusable/FormInput.vue';
import FormTextarea from '@/components/reusable/FormTextarea.vue';
import { setQuestion } from '@/api/index'
import { toast } from 'vue3-toastify'
import throttle from 'lodash/throttle'

export default {
  name: 'HomeAbout',
  components: {
    Button,
    FormInput,
    FormTextarea
  },
  props: {
    info: {
      type: Object
    }
  },
  data: () => {
    return {
      theme: '',
      form: {
        firstName: '',
        name: '',
        email: '',
        phone: '',
        title: '',
        content: '',

      },
      left: Math.floor(Math.random() * 10),
      right: Math.floor(Math.random() * 10),
      loading: false
    };
  },
  created() {
    this.theme = localStorage.getItem('theme') || 'light';
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem('theme') || 'light';
    // this.total = this.left + this.right
  },
  updated() {
    feather.replace();
  },
  computed: {
    total() {
      return this.left + this.right
    },
    $throttle() {
      return throttle
    }
  },
  methods: {
    postData() {
      if (!this.loading) {
        this.loading = true
        setQuestion(this.form).then(res => {
          this.loading = false
          if (res.code === 0) {
            toast('success', {
              "type": "success",
              "position": "top-center",
              "transition": "slide",
              "dangerouslyHTMLString": true
            });
            this.form = {
              firstName: '',
              name: '',
              email: '',
              phone: '',
              title: '',
              content: '',
              num: ''
            }
            this.left = Math.floor(Math.random() * 10)
            this.right = Math.floor(Math.random() * 10)
          } else {
            toast(res.msg || 'failed', {
              "type": "error",
              "position": "top-center",
              "transition": "slide",
              "dangerouslyHTMLString": true
            });
          }
        }).catch(() => {
          this.loading = false
        })
      }
    },
    submitForm() {
      if (this.form.num && this.form.num == this.total) {
        throttle(this.postData, 500).call(this)
      }
    }
  },
};
</script>

<template>
  <div class="w-full bg-gray-100 mt-12 sm:mt-20 sm:py-8">
    <div class="sm:container sm:mx-auto">
      <section class="flex flex-col items-start sm:flex-row justify-center py-4 gap-4">
        <!-- Banner left contents -->
        <div class="w-full md:w-2/6">
          <div class="pl-6 sm:pl-0 text-left flex flex-col justify-between">
            <p class="font-general-medium text-black font-bold py-4 mb-4">Questions？</p>
            <p class="font-general-medium text-black font-bold py-4 mb-4">Suggestions？</p>
            <p class="font-general-medium text-black font-bold py-4 mb-4">Contact us~</p>
            <div class="flex items-center home-email py-4 mb-4">
              <i class="w-5 h-5 flex-none" data-feather="phone"></i>
              <div class="flex align-center font-general-medium text-black font-bold ml-2 whitespace-pre break-all">
                Tel：{{
                  info.phone
                }}</div>
            </div>
            <div class="flex items-center home-email py-4 mb-4">
              <i class="w-6 h-6 flex-none" data-feather="mail">

              </i>
              <div class="flex align-center font-general-medium text-black font-bold ml-2 whitespace-pre break-all">
                Email：{{
                    info.email
                  }}</div>
            </div>
            <p class="text-sm mb-1 border-t border-slate-300/80 border-solid pt-4">{{ info.adress }}</p>
            <p class="text-sm text-black pt-6">Copyright: © [2024] [IYDF]. All content,
              including images and text, is protected by copyright and cannot be reproduced without prior written
              consent.</p>
          </div>
        </div>

        <!-- Banner right illustration -->
        <div class="w-full md:w-4/6 text-right float-right px-6 sm:px-0">
          <form class="w-full text-left" @submit.prevent="submitForm">
            <div class="block sm:flex">
              <div class="flex-1 mb-4 md:mt-0">
                <FormInput label="First Name：" inputIdentifier="name" v-model="form.name" />
              </div>
              <div class="flex-1 ml-0 md:ml-2">
                <FormInput label="Last Name：" inputIdentifier="firstName" v-model="form.firstName" />
              </div>
            </div>
            <div class="block sm:flex my-4">
              <div class="flex-1 mb-4 md:mt-0">
                <FormInput label="Email：" inputIdentifier="email" inputType="email" v-model="form.email" />
              </div>
              <div class="flex-1 ml-0 md:ml-2">
                <FormInput label="Phone Number：" inputIdentifier="phone" v-model="form.phone" />
              </div>
            </div>
            <FormInput label="Subject：" inputIdentifier="type" v-model="form.title" />
            <div class="my-4">
              <FormTextarea label="Message" inputIdentifier="content" textareaIdentifier="content"
                v-model="form.content" />
            </div>
            <div class="w-full">
              <div class="text-primary-black text-sm mb-1">Answer this question：{{ left }} + {{ right }}=？</div>
              <FormInput label="" inputIdentifier="email" v-model="form.num" />
            </div>

            <div class="mt-7 pb-4 sm:pb-1">
              <Button title="Submit" class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-black rounded-full" type="submit"
                v-if="form.num == total" aria-label="Submit Request" :loading="loading" />
              <Button title="Submit" :loading="loading"
                class="px-4 sm:px-6 py-2 sm:py-2.5 text-white bg-[#cccc] rounded-full" v-else
                aria-label="Submit Request" />
            </div>
          </form>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped></style>
