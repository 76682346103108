<!--合作-->

<script>
	import feather from 'feather-icons';
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import {
		Navigation,
		Autoplay
	} from 'swiper/modules';
	import 'swiper/css/navigation';
	import 'swiper/css/autoplay';
	// Import Swiper styles
	import 'swiper/css';
	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		name: 'HomeCooperate',
		props: {
			info: {
				type: Object
			}
		},
		data: () => {
			return {
				theme: '',
				modules: [Navigation, Autoplay],
				textList: [
					'Our Partners-British International Youth Development Foundation-India IYDF-IYDF',
					'Our Partners-International Youth Development Foundation-British IYDF-IYDF',
					'Our Partners-British International Youth Development Foundation-British IYDF-IYDF',
					'Our Partners-International Youth Development Foundation-India IYDF-IYDF'
				]
			};
		},
		created() {
			this.theme = localStorage.getItem('theme') || 'light';
		},
		mounted() {
			feather.replace();
			this.theme = localStorage.getItem('theme') || 'light';
		},
		updated() {
			feather.replace();
		},
		methods: {},
	};
</script>

<template>
	<div class="sm:container sm:mx-auto mt-12 sm:mt-24">
		<div class="w-full flex justify-center">
			<img src="@/assets/images/cooperate_icon.webp" />
		</div>
		<p class="text-center mt-4 sm:mt-8 text-ternary-dark font-bold">{{ info.title }}</p>
		<div class="mt-4 sm:mt-8 w-full lg:w-5/6 lg:mx-auto flex justify-center">
			<div class="swiper">
				<swiper :slides-per-view="1" :modules="modules" autoplay :navigation="{
      nextEl: '.swiper-button-next.sp2',
      prevEl: '.swiper-button-prev.sp2',
    }" :space-between="50" loop>
					<swiper-slide v-for="(item, i) in info.imagesList" :key="i">
						<img class="w-full" :src="item.url" :alt="textList[i]" />
					</swiper-slide>
				</swiper>
				<div class="swiper-button-prev sp2 w-10 h-10 rounded-full bg-[#111]">
				</div>
				<div class="swiper-button-next sp2 w-10 h-10 rounded-full bg-[#111]">
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	.swiper-button-prev {
		@apply text-white;

		&::after {
			@apply text-lg;
		}
	}

	.swiper-button-next {
		@apply text-white;

		&::after {
			@apply text-lg;
		}
	}
</style>